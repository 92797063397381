import isEmpty from "is-empty";

const validateCheckout = (order) => {
  let errors = {};

  if (isEmpty(order.firstName)) {
    errors.firstName = "Your first name is required.";
  }
  if (isEmpty(order.lastName)) {
    errors.lastName = "Your last name is required.";
  }
  if (isEmpty(order.mobilePhone)) {
    errors.mobilePhone = "Your phone number is required.";
  }
  if (isEmpty(order.email)) {
    errors.email = "Your email is required.";
  }
  if (isEmpty(order.paymentMethod)) {
    errors.paymentMethod = "Please choose how you want to pay.";
  }
  if (isEmpty(errors)) {
    return { success: true };
  } else return { success: false, errors };
};

export default validateCheckout;
