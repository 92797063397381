import { createSlice } from "@reduxjs/toolkit";
import registerUser from "./registerActions";
export const registerSlice = createSlice({
  name: "register",
  initialState: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobilePhone: "",
    registerLoading: false,
    errors: {},
    isSuccess: false,
    isError: false,
    message: "",
    dispatcher: "",
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: (state) => {
      state.confirmPassword = "";
      state.email = "";
      state.errors = {};
      state.firstName = "";
      state.lastName = "";
      state.registerLoading = false;
      //state.message = "";
      state.mobilePhone = "";
      state.password = "";
      state.isSuccess = false;
      state.isError = false;
      state.dispatcher = "";
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [registerUser.fulfilled]: (state, { payload }) => {
      const { message } = payload;
      state.isSuccess = true;
      state.message = message || payload;
      state.registerLoading = false;
      state.errors = {};
      state.dispatcher = "register";
    },
    [registerUser.pending]: (state) => {
      state.registerLoading = true;
    },
    [registerUser.rejected]: (state, { payload }) => {
      // destructure errors from payload since payload can be an object with keys errors and isSuccess
      const { errors } = payload;
      state.errors = errors || payload;
      state.registerLoading = false;
      state.isError = true;
      state.message = payload.message ?? "Check registration details.";
      state.dispatcher = "register";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState, resetState } = registerSlice.actions;
export default registerSlice.reducer;
