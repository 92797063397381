import { createSlice } from "@reduxjs/toolkit";
import resetAccountActions from "./resetActions";
export const resetAccountSlice = createSlice({
  name: "resetAccount",
  initialState: {
    email: "",
    resetLoading: false,
    errors: {},
    isSuccess: false,
    isError: false,
    message: "",
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: (state) => {
      state.email = "";
      state.errors = {};
      state.resetLoading = false;
      //state.message = "";
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [resetAccountActions.recoverPassword.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.message = payload.message ?? "We received your email";
      state.resetLoading = false;
      state.errors = {};
    },
    [resetAccountActions.recoverPassword.pending]: (state) => {
      state.resetLoading = true;
    },
    [resetAccountActions.recoverPassword.rejected]: (state, { payload }) => {
      // destructure errors from payload since payload can be an object with keys errors and isSuccess
      const { errors } = payload;
      state.errors = errors || payload;
      state.resetLoading = false;
      state.isError = true;
      state.message = payload.message ?? "Check credentials.";
    },
    [resetAccountActions.resendVerification.fulfilled]: (state, { payload }) => {
        state.isSuccess = true;
        state.message = payload.message ?? "We received your email";
        state.resetLoading = false;
        state.errors = {};
      },
      [resetAccountActions.resendVerification.pending]: (state) => {
        state.resetLoading = true;
      },
      [resetAccountActions.resendVerification.rejected]: (state, { payload }) => {
        // destructure errors from payload since payload can be an object with keys errors and isSuccess
        const { errors } = payload;
        state.errors = errors || payload;
        state.resetLoading = false;
        state.isError = true;
        state.message = payload.message ?? "Check credentials.";
      },
  },
});

// Action creators are generated for each case reducer function
export const { setState, resetState } = resetAccountSlice.actions;
export default resetAccountSlice.reducer;
