import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setAuthToken } from "../../../utils/setAuthToken";
import { validLogin } from "../../../utils/user";
import baseUrl from "../../../api-config";
const loginUser = createAsyncThunk("auth/loginUser", async (data, thunkAPI) => {
  try {
    const userCredentials = {
      ...data,
    };
    const validUser = validLogin(userCredentials);

    if (validUser.success) {
      const response = await axios.post(
        `${baseUrl}/auth/login`,
        userCredentials
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } else return thunkAPI.rejectWithValue(validUser);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export default loginUser;

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (args, { dispatch, rejectWithValue }) => {
    localStorage.removeItem("chmtoken");
    setAuthToken(axios);
    try {
      const { setState } = await import("./loginSlice");
      dispatch(setState({ user: {} }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
