import { MDBContainer } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

export default function RefundPolicy() {

    return <MDBContainer>
        <h6>Effective Date: Jun 29, 2023</h6>
        <p>This Refund Policy ("Policy") outlines the terms and conditions for requesting and receiving refunds for purchases made on chomainefoods.com . By making a purchase on the website, you agree to comply with this Policy.</p>
        <h5>Eligibility for Refunds:</h5>
        <ol>
            <li>Products:<br />
                Refunds may be eligible for food products that are damaged, defective, or not as described at the time of delivery. Perishable or consumable items, such as fresh produce or prepared meals, may have specific eligibility criteria due to their nature.</li>
        </ol>
        <h5>Refund Requests:</h5>
        <ol>
            <li>Notification:<br />
                To request a refund, you must notify us within 8 hours of receiving the product or service, providing details of the issue or reason for the refund request. Notification can be made through email at info@chomainefoods.com.</li>
            <li>Proof of Purchase:
                You must provide proof of purchase, such as the order number or receipt, to initiate the refund process. Failure to provide adequate proof may result in the denial of the refund request.</li>
        </ol>
        <h5>Refund Process:</h5>
        <ol>
            <li>Evaluation:<br />
                Upon receiving your refund request, we will assess the validity and eligibility based on the provided information. We may require additional details or evidence, such as photographs or a return of the product, to evaluate the request thoroughly.</li>
            <li>Decision:
                We will notify you of our decision regarding your refund request within a reasonable timeframe. If approved, we will initiate the refund process.</li>
            <li>Refund Method:
                Refunds will be issued using the same payment method used for the original purchase, unless otherwise agreed upon. Depending on the payment method and financial institution, it may take several business days for the refund to be processed and reflected in your account.</li>
        </ol>
        <h5>Non-Refundable Items:</h5>
        <ol>
            <li>Non-eligible Products:<br />
                Certain products, such as personalized or custom-made items but not limited to, may not be eligible for refunds unless they are damaged, defective, or not as described.</li>
            <li>Non-eligible Services:
                Services that have been fully or partially rendered may not be eligible for refunds, except in cases of breach of contract or failure to provide the agreed-upon service.</li>
            <li>Refund Method:
                Refunds will be issued using the same payment method used for the original purchase, unless otherwise agreed upon. Depending on the payment method and financial institution, it may take several business days for the refund to be processed and reflected in your account.</li>
        </ol>
        <h5>Return of Products:</h5>
        <p>In some cases, if a return of the product is required to process the refund, we will provide you with instructions for the return goods. You will be responsible for the cost of return shipping, unless the return is due to our error or a defective product.</p>
        <h5>Changes to the Refund Policy:</h5>
        <p>We reserve the right to modify or update this Refund Policy at any time without prior notice. Any changes will be effective immediately upon posting the updated Policy on the website. It is your responsibility to review this Policy periodically for any updates.</p>
        <h5>Contact Us:</h5>
        <p>If you have any questions, concerns, or inquiries regarding this Refund Policy or the refund process, please contact us at info@chomainefoods.com</p>
        <p>By making a purchase on <Link to="#">chomainefoods.com</Link>, you acknowledge that you have read, understood, and agreed to the terms and conditions of this Refund Policy.</p>
    </MDBContainer>

}