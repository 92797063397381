import { createSlice } from "@reduxjs/toolkit";
import { updateProduct } from "../product_table/actions";
import { createProduct } from "./addActions";
export const addSlice = createSlice({
  name: "addproduct",
  initialState: {
    product: {},
    errors: {},
    addProductLoading: false,
    isSuccess: false,
    isError: false,
    isEdit: false,
    editId: '',
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: (state) => {
      state.errors = {};
      state.addProductLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isEdit = false;
      state.product = {};
      state.editId = '';
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [createProduct.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.message = payload.message ?? "Product added";
      state.addProductLoading = false;
      state.errors = {};
      state.product = {}
    },
    [createProduct.pending]: (state) => {
      state.addProductLoading = true;
    },
    [createProduct.rejected]: (state, { payload }) => {
      state.errors = payload.errors ?? payload;
      state.addProductLoading = false;
      state.isError = true;
      state.message = payload?.message ?? "Error adding product";
    },
    [updateProduct.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.message = payload.message ?? "Product added";
      state.addProductLoading = false;
      state.errors = {};
      state.product = {}
      state.message = "Product Updated Successfully";
    },
    [updateProduct.rejected]: (state, { payload }) => {
      state.errors = payload.errors ?? payload;
      state.addProductLoading = false;
      state.isError = true;
      state.message = payload?.message ?? "Error updating product";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState, resetState } = addSlice.actions;
export default addSlice.reducer;
