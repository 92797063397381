import isEmpty from "is-empty"
import ValidateEmail from "../utils/user"
export default function validatePromotion(promotion) {
    let errors = {}
    if (!promotion.title) errors.title = 'Cannot create promotion without title.'
    if (!promotion.content) errors.content = 'Please enter content for promotion.'
    if (!promotion.startDate) errors.startDate = "Enter start date for promotion."
    if (!promotion.endDate) errors.endDate = "Enter end date for promotion."
    if(!promotion.percentOff || promotion.percentOff < 1) errors.percentOff = "Enter percentage off as whole number"

    return { success: isEmpty(errors), errors }
}

export const validateAcceptOffer = (promotion) => {
    let errors = {}
    if(isEmpty(promotion.email) || !ValidateEmail(promotion.email)) errors.email = "Enter valid email."
    return { success: isEmpty(errors), errors }
}