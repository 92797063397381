import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import isEmpty from "is-empty";
import validateCheckout from "../../utils/validateCheckoutDetails";
import { getLoggedInUser } from "../auth/login/loginSlice";
import baseUrl from "../../api-config";
import { setAuthToken } from "../../utils/setAuthToken";
export const createOrder = createAsyncThunk(
  "order/createOrder",
  async (data, thunkAPI) => {
    try {
      const order = { ...data.userDetails, paymentMethod: data?.paymentMethod };
      const validOrder = validateCheckout(order);
      if (validOrder.success) {
        const response = await axios.post(`${baseUrl}/order`, data);
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } else return thunkAPI.rejectWithValue(validOrder.errors);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getOrders = createAsyncThunk(
  "order/getOrders",
  async (
    {
      status = null,
      perPage = 10,
      pageNo = 1,
      startDate = null,
      endDate = null,
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(getLoggedInUser());
      const { startDate, endDate } = thunkAPI.getState().dashboard;
      const { user } = thunkAPI.getState().auth.login;
      const url = `${baseUrl}/order?perPage=${perPage}&pageNo=${pageNo}`;
      let query = "";
      if (isEmpty(user) || user.role[0] === "admin") {
        if (startDate && endDate)
          query = status
            ? `&status=${status}&startDate=${startDate}&endDate=${endDate}`
            : url;
        else if (startDate) {
          query = status ? `&status=${status}&startDate=${startDate}` : url;
        } else if (endDate)
          query = status ? `&status=${status}&endDate=${endDate}` : url;
          else query = status ? `&status=${status}` : url;
      } else {
        query = status
          ? `&status=${status}&userId=${user.id}`
          : `&userId=${user.id}`;
      }

      if (startDate && endDate) {
        query = `${query}&startDate=${startDate}&endDate=${endDate}`;
      } else if (startDate) query = `${query}&startDate=${startDate}`;

      const response = await axios.get(
        `${url === query ? query : `${url}${query}`}`
      );

      /*  const response = await axios.get(
        url, {params: startDate, endDate, status: status}
      ); */

      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data ?? error.response);
    }
  }
);
export const getOrder = createAsyncThunk(
  "order/getOrder",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}/order/${id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "order/deleteOrder",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${baseUrl}/order/${id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async ({ id, data, action }, thunkAPI) => {
    let response = null;
    thunkAPI.dispatch(getLoggedInUser());
    const { user } = thunkAPI.getState().auth.login;
    if (isEmpty(user))
      return thunkAPI.rejectWithValue({
        status: 401,
        message: "Session timed out. Relogin to carry out this action.",
      });
    setAuthToken(axios);
    if (action) {
      response = await axios.put(
        `${baseUrl}/order/${id}?updateStatus=true`,
        data
      );
    } else response = await axios.put(`${baseUrl}/order/${id}`, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const downloadCsv = createAsyncThunk(
  "order/downloadOrders",
  async ({ status, fileTitle }, thunkAPI) => {
    const { startDate, endDate } = thunkAPI.getState().dashboard;
    const response = await axios.get(`${baseUrl}/order/download`, {
      params: {
        startDate,
        endDate,
        status: status === "all" || !status ? null : status,
      },
    });

    if (response.status === 200) {
      const blob = new Blob([response.data.csvFile], { type: "text/csv" });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);

      // Creating an anchor(a) tag of HTML
      const a = document.createElement("a");

      // Passing the blob downloading url
      a.setAttribute("href", url);

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute("download", `${fileTitle}.csv`);

      // Performing a download with click
      a.click();
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
