import { createSlice } from "@reduxjs/toolkit";
import { getBasket } from "./basketActions";
export const basketSlice = createSlice({
  name: "basket",
  initialState: {
    basket: [],
    basketLoading: false,
    errors: {},
    isSuccess: false,
    isError: false,
    message: "",
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {
    [getBasket.fulfilled]: (state, { payload }) => {
      state.basket = payload;
      state.basketLoading = false;
    },
    [getBasket.pending]: (state) => {
      state.basketLoading = true;
    },
    [getBasket.rejected]: (state, { payload }) => {
      state.errors = payload
      state.basketLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState } = basketSlice.actions;
export default basketSlice.reducer;
