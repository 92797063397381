import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ValidatePasswords } from "../../../utils/user";
import baseUrl from "../../../api-config"
const updatePassword = createAsyncThunk(
  "auth/update",
  async (data, thunkAPI) => {
    try {
      const userCredentials = {
        ...data
      };
      const validUser = ValidatePasswords(userCredentials);
      if (validUser) {
        const response = await axios.post(`${baseUrl}/auth/reset`, userCredentials);
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } else return thunkAPI.rejectWithValue(validUser);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export default updatePassword;
