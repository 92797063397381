import { createAsyncThunk } from "@reduxjs/toolkit";
import { validateCategory } from "../../utils/category";
import axios from "axios";
import { setAuthToken } from "../../utils/setAuthToken";
import baseUrl from "../../api-config";
export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (data, thunkAPI) => {
    try {
      let category = {
        ...data,
      };
      let validCategory = validateCategory(category);

      if (validCategory.success) {
        setAuthToken(axios);
        const response = await axios.post(`${baseUrl}/category`, category);
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } else return thunkAPI.rejectWithValue(validCategory.errors);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}/category`);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (id, thunkAPI) => {
    try {
      setAuthToken(axios);
      const response = await axios.delete(`${baseUrl}/category/${id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async (data, thunkAPI) => {
    let category = {
      ...data,
    };

    const validCategory = validateCategory(category);
    if (validCategory.success) {
      try {
        setAuthToken(axios);
        const response = await axios.put(`${baseUrl}/category/${data._id}`, category);
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    } else return thunkAPI.rejectWithValue(validCategory.errors);
  }
);
