import { createAsyncThunk } from "@reduxjs/toolkit";
import isEmpty from "is-empty";
import { setState } from "./basketSlice";
import { setState as setOrderTotal } from "../orders/orderSlice";
import axios from "axios";
import { getLoggedInUser } from "../auth/login/loginSlice";
import getSimilarItems, {
  getUnsimilarItems,
  handleSimilarItems,
} from "../../utils/basket";
import baseUrl from "../../api-config";
export const addToBasket = createAsyncThunk(
  "basket/add",
  async (
    product,
    { getState, dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { basket } = getState().basket;
      if (!basket.length) {
        dispatch(setState({ basket: [{ product, quantity: 1 }] }));
        localStorage.setItem(
          "basket",
          JSON.stringify([...basket, { product, quantity: 1 }])
        );
      } else {
        const existingProductIndex = basket.findIndex(
          (item) => item.product._id === product._id
        );
        const existingProduct = { ...basket[existingProductIndex] };
        if (isEmpty(existingProduct)) {
          dispatch(setState({ basket: [...basket, { product, quantity: 1 }] }));
          localStorage.setItem(
            "basket",
            JSON.stringify([...basket, { product, quantity: 1 }])
          );
        } else {
          const updProd = {
            ...existingProduct,
            quantity: (existingProduct.quantity += 1),
          };
          const temp = [...basket];
          temp[existingProductIndex] = updProd;
          dispatch(setState({ basket: [...temp] }));
          localStorage.setItem("basket", JSON.stringify(temp));
        }
      }
      return fulfillWithValue(product);
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);

export const calculateBasketTotal = createAsyncThunk(
  "basket/calculateTotal",
  async (discounted = null, { dispatch, getState }) => {
    const basket = getLocalBasket();
    const { order } = getState().orders;
    let basketTotal = 0;
    basket.map((item) =>
      item.product?.discounted && item.quantity >= item.product?.discountTrigger
        ? (basketTotal += item.product?.discountPrice * item.quantity)
        : (basketTotal += item.product?.price * item.quantity)
    );
    if (discounted) {
      const converted = discounted / 100;
      const discountAmt = converted * basketTotal;
      basketTotal -= discountAmt;
    }
    dispatch(setOrderTotal({ order: { ...order, orderTotal: basketTotal } }));
  }
);

export const increment = createAsyncThunk(
  "basket/increment",
  async (index, { getState, dispatch }) => {
    const { basket } = getState().basket;
    const updateRow = { ...basket[index] };
    const newAmount = parseFloat(updateRow?.quantity) + 1;
    let newB = [...basket];
    newB[index] = { ...updateRow, quantity: newAmount };
    dispatch(setState({ basket: newB }));
    localStorage.setItem("basket", JSON.stringify(newB));
    dispatch(calculateBasketTotal());
  }
);

export const decrement = createAsyncThunk(
  "basket/decrement",
  async (index, { getState, dispatch }) => {
    const { basket } = getState().basket;
    const updateRow = { ...basket[index] };
    const newAmount = parseFloat(updateRow?.quantity) - 1;
    let newB = [...basket];
    if (newAmount <= 0) {
      newB = newB.filter((x) => x.product._id !== updateRow.product._id);
    } else {
      newB[index] = { ...updateRow, quantity: newAmount };
    }
    dispatch(setState({ basket: newB }));
    localStorage.setItem("basket", JSON.stringify(newB));
    dispatch(calculateBasketTotal());
  }
);
export const setAmount = createAsyncThunk(
  "basket/setAmount",
  async ({ index, amount }, { getState, dispatch }) => {
    const { basket } = getState().basket;
    const updateRow = basket[index];
    let newB = [...basket];
    if (parseFloat(amount) <= 0) {
      newB = newB.filter((x) => x?.product?._id !== updateRow?.product?._id);
    } else {
      if (parseFloat(amount)) {
        newB[index] = { ...updateRow, quantity: parseFloat(amount) };
      } else newB[index] = { ...updateRow, quantity: amount };
    }
    dispatch(setState({ basket: newB }));
    localStorage.setItem("basket", JSON.stringify(newB));
    dispatch(calculateBasketTotal());
  }
);

export const emptyBasket = createAsyncThunk(
  "basket/emptyBasket",
  async (args, { dispatch }) => {
    dispatch(setState({ basket: [] }));
    localStorage.removeItem("basket");
    dispatch(calculateBasketTotal());
  }
);

export const removeFromBasket = createAsyncThunk(
  "basket/removeFromBasket",
  async (
    product,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { basket } = getState().basket;
      let basketCopy = [...basket];
      basketCopy = basketCopy.filter((x) => x.product !== product);
      dispatch(setState({ basket: basketCopy }));
      localStorage.setItem("basket", JSON.stringify(basketCopy));
      dispatch(calculateBasketTotal());
      return fulfillWithValue(product);
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);

export const getBasket = createAsyncThunk(
  "basket/getBasket",
  async (args, { dispatch, getState, rejectWithValue }) => {
    try {
      //check if a user is currently logged in
      dispatch(getLoggedInUser());
      const { user } = getState().auth.login;
      let response = null;
      let returnBasket = [];
      if (!isEmpty(user)) {
        //if a user is logged in. Fetch potential basket from db
        response = await axios.get(`${baseUrl}/basket/${user.id}`);

        if (response.status === 200) {
          //get basket from localStorage
          const basket = getLocalBasket();
          //access basket items from api response.
          const { items } = response.data;
          if (basket?.length && items?.length) {
            const similarItems = getSimilarItems(basket, items);
            const unsimilarItems = getUnsimilarItems(basket, items);
            if (basket.length >= items.length) {
              if (similarItems.length) {
                //all similar items came from basket so use items array to update
                //local basket.
                returnBasket = handleSimilarItems(items, similarItems);
                returnBasket = [...returnBasket, ...unsimilarItems];
              } else returnBasket = unsimilarItems;
            } else if (basket?.length < items.length) {
              if (similarItems.length) {
                returnBasket = handleSimilarItems(basket, similarItems);
                returnBasket = [...returnBasket, ...unsimilarItems];
              } else returnBasket = unsimilarItems;
            }
          } else if (!basket?.length && items?.length) {
            returnBasket = items;
          } else if (basket.length && !items?.length) {
            returnBasket = basket;
          }
          //delete online basket
          if (items?.length) {
            await axios.delete(`/api/basket/${response.data._id}`);
          }
        }
      } else {
        returnBasket = getLocalBasket();
      }
      if (returnBasket?.length) {
        localStorage.setItem("basket", JSON.stringify(returnBasket));
        dispatch(calculateBasketTotal());
      }
      return returnBasket;
    } catch (error) {
      if (error?.response?.status === 404) {
        const basket = getLocalBasket();
        dispatch(calculateBasketTotal());
        return basket;
      } else return rejectWithValue(error?.response?.data ?? error);
    }
  }
);

const getLocalBasket = () => {
  const basket = localStorage.getItem("basket");
  if (basket?.length) {
    return JSON.parse(basket);
  } else return [];
};

export const saveBasket = createAsyncThunk(
  "basket/saveBasket",
  async (args, thunkAPI) => {
    try {
      const basket = getLocalBasket();
      const { user } = thunkAPI.getState().auth.login;
      if (basket?.length && !isEmpty(user)) {
        const existingBasket = await axios.get(`${baseUrl}/basket/${user.id}`);
        if (existingBasket.status === 200) {
          const response = await axios.put(
            `${baseUrl}/basket/${existingBasket.data._id}`,
            {
              items: basket,
            }
          );
          if (response.status === 200) {
            localStorage.removeItem("basket");
            return response.data;
          }
        }
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        const basket = getLocalBasket();
        const { user } = thunkAPI.getState().auth.login;
        try {
          const response = await axios.post(`${baseUrl}/basket`, {
            items: basket,
            userId: user.id,
          });
          if (response.status === 200) {
            localStorage.removeItem("basket");
            thunkAPI.dispatch(emptyBasket());
            thunkAPI.dispatch(calculateBasketTotal());
            return thunkAPI.fulfillWithValue(response.data);
          }
        } catch (error) {
          throw error;
        }
      }

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
