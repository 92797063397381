import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    modal: false,
    pointer: false,
    pointerName: "",
    modalName: "",
    modalContent: {},
    pointerContent: {},
  },
  reducers: {
    toggleShow: (state, { payload }) => {
      state.modal = !state.modal;
      state.modalName = payload;
    },
    setModalContent: (state, { payload }) => {
      state.modalContent = payload;
    },
    togglePointer: (state, { payload }) => {
      state.pointer = !state.pointer;
      state.pointerName = payload;
    },
    setPointerContent: (state, { payload }) => {
      state.pointerContent = payload;
    },
    setModal: (state, { payload }) => {
      state.modal = payload
    },
    resetState: (state) => {
      state.modal = false;
      state.pointer = false;
      state.pointerName = "";
      state.modalName = "";
      state.modalContent = {};
      state.pointerContent = {};
    },
  },
});

export const { togglePointer, toggleShow, resetState, setModalContent, setPointerContent, setModal } =
  modalSlice.actions;
export default modalSlice.reducer;
