import { createSlice } from "@reduxjs/toolkit";
import {
  createCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from "./categoryActions";
export const categorySlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    category: {},
    categoriesLoading: false,
    isSuccess: false,
    message: "",
    isError: false,
    errors: {},
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: (state) => {
      state.message = "";
      state.errors = {};
      state.isSuccess = false;
      state.category = {};
      state.isError = false;
      state.categoriesLoading = false;
    },
  },
  extraReducers: {
    [createCategory.fulfilled]: (state, { payload }) => {
      state.categoriesLoading = false;
      state.errors = {};
      state.isSuccess = true;
      state.categories.push(payload);
      state.message = "Category created successfully";
    },
    [createCategory.pending]: (state) => {
      state.categoriesLoading = true;
    },
    [createCategory.rejected]: (state, { payload }) => {
      state.categoriesLoading = false;
      state.errors = payload?.errors || payload;
      state.message = payload?.message || "Unexpected Error creating category";
      state.isError = true;
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      state.categories = payload.categories;
      state.categoriesLoading = false;
    },
    [getCategories.pending]: (state) => {
      state.categoriesLoading = true;
    },
    [getCategories.rejected]: (state, action) => {
      state.categoriesLoading = false;
    },
    [deleteCategory.fulfilled]: (state, { payload }) => {
      state.categoriesLoading = false;
      state.isSuccess = true;
      state.message = "Category Deleted";
      state.categories = state.categories.filter((x) => x._id !== payload.id);
    },
    [deleteCategory.pending]: (state) => {
      state.categoriesLoading = true;
    },
    [deleteCategory.rejected]: (state, { payload }) => {
      state.categoriesLoading = false;
      state.message = payload?.message || "Error fetching categories";
      state.isSuccess = false;
      state.isError = true;
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      state.categoriesLoading = false;
      state.isSuccess = true;
      const index = state.categories.findIndex(
        (category) => category._id === payload._id
      );
      state.categories[index] = payload;
      state.message = "Category Updated Successfully";
    },
    [updateCategory.pending]: (state) => {
      state.categoriesLoading = true;
    },
    [updateCategory.rejected]: (state, { payload }) => {
      state.categoriesLoading = false;
      state.isError = true;
      state.message = payload?.message || "Error updating category";
      state.errors = payload;
    },
  },
});

export const { resetState, setState } = categorySlice.actions;
export default categorySlice.reducer;
