import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import OrderSummary from "./features/orders/orderSummary";
import ProductDetails from "./features/product/product_details";
import Orders from "./features/user/orders";
import { ToastContainer } from "react-toastify";
import RiseLoader from "react-spinners/RiseLoader";
import AdminUsersPage from "./features/admin/users";
import AdminPromotionPage from "./features/admin/promotions";
import Legal from "./features/legal";
import AdminMailingList from "./features/admin/users/mailingList";
const ViewOrder = React.lazy(() => import("./features/orders/viewOrder"));
const AdminLayout = React.lazy(() => import("./features/admin"));
const AdminCategoryPage = React.lazy(() =>
  import("./features/admin/categories")
);
const AdminDashboard = React.lazy(() => import("./features/admin/dashboard"));
const AdminOrderPage = React.lazy(() => import("./features/admin/orders"));
const AdminProduct = React.lazy(() => import("./features/admin/product"));
const ForgotPassword = React.lazy(() =>
  import("./features/auth/forgot_password")
);
const Login = React.lazy(() => import("./features/auth/login"));
const Register = React.lazy(() => import("./features/auth/register"));
const UpdatePassword = React.lazy(() =>
  import("./features/auth/update_password")
);
const ResendToken = React.lazy(() =>
  import("./features/auth/resend_verification")
);
const VerifiedPage = React.lazy(() => import("./features/auth/verified"));
const VerifyPage = React.lazy(() => import("./features/auth/verify"));
const Basket = React.lazy(() => import("./features/basket"));
const Checkout = React.lazy(() => import("./features/checkout"));
const Footer = React.lazy(() => import("./features/footer"));
const ProductPage = React.lazy(() => import("./features/product"));
const Splash = React.lazy(() => import("./features/splash"));
const Header = React.lazy(() => import("./features/header"));
const Profile = React.lazy(() => import("./features/user/profile"));
const override = {
  display: "block",
  margin: "0 auto",
};
function App() {
  return (
    <div className="App">
      <Suspense
        fallback={
          <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
            <RiseLoader color="royalblue" cssOverride={override} size={30} />
            {/* <div>
              
              Just a sec...
            </div> */}
          </div>
        }
      >
        <Router>
          <Routes>
            <Route path="/" element={<Header />}>
              <Route path="/" element={<Splash />} />
              <Route path="/products" element={<ProductPage />} />
              <Route path="products/:productId" element={<ProductDetails />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="order/:orderId" element={<ViewOrder />} />
              <Route
                path="auth/verify"
                element={<VerifyPage key={"ver_1"} />}
              />
              <Route
                path="auth/verified"
                element={<VerifiedPage key={"ver_2"} />}
              />
              <Route path="auth/forgot" element={<ForgotPassword />} />
              <Route path="auth/resend-token" element={<ResendToken />} />
              <Route path="auth/:token" element={<UpdatePassword />} />
              <Route path="basket" element={<Basket />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="order-summary/:orderId" element={<OrderSummary />} />
              <Route path="/user/orders" element={<Orders />} />
              <Route path="/user/profile" element={<Profile />} />
            </Route>
            <Route path="/admin/*" element={<AdminLayout />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="products" element={<AdminProduct />} />
              <Route path="orders" element={<AdminOrderPage />} />
              <Route path="categories" element={<AdminCategoryPage />} />
              <Route path="users" element={<AdminUsersPage />} />
              <Route path="promotions" element={<AdminPromotionPage />} />
              <Route path="mailing-list" element={<AdminMailingList />} />
            </Route>
          </Routes>
          <ToastContainer />
          <Footer />
          <Legal />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
