import { createSlice } from "@reduxjs/toolkit";
import loginActions from "./loginActions";
import jwt_decode from "jwt-decode";
import { updateUser } from "../../user/profile/actions";
export const loginSlice = createSlice({
  name: "login",
  initialState: {
    email: "",
    password: "",
    user: {},
    loginLoading: false,
    errors: {},
    isSuccess: false,
    isError: false,
    message: "",
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    getLoggedInUser: (state) => {
      let token = localStorage.getItem("chmtoken");
      if (token) {
        const user_ = jwt_decode(token);
        if (Date.now() >= user_.exp * 1000) {
          localStorage.removeItem("chmtoken")
          state.user = {}
        } else {
          state.user = {
            ...user_,
            address: {
              ...user_.address,
              parish: { label: user_.address.parish, value: user_.address.parish },
            },
          };
        }
      }
    },
    logout: (state) => {
      state.user = {};
      localStorage.removeItem("chmtoken");
    },
    resetState: (state) => {
      state.email = "";
      state.errors = {};
      state.loginLoading = false;
      //state.message = "";
      state.password = "";
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [loginActions.fulfilled]: (state, { payload }) => {
      const { token } = payload;
      localStorage.setItem("chmtoken", token);
      state.isSuccess = true;
      state.message = "Log in successful";
      state.loginLoading = false;
      state.isError = false
      state.errors = {}
      state.user = jwt_decode(token);
    },
    [loginActions.pending]: (state) => {
      state.loginLoading = true;
    },
    [loginActions.rejected]: (state, { payload }) => {
      // destructure errors from payload since payload can be an object with keys errors and isSuccess
      const { errors } = payload;
      state.errors = errors || payload;
      state.loginLoading = false;
      state.isError = true;
      state.message = payload.message ?? "Check log in credentials.";
    },
    // Add reducers for additional action types here, and handle loading state as needed
    [updateUser.fulfilled]: (state, { payload }) => {
      const { token } = payload;
      localStorage.setItem("chmtoken", token);
      state.isSuccess = true;
      state.message = "Profile updated successfully";
      state.loginLoading = false;
      state.isError = false
      state.errors = {}
      state.user = jwt_decode(token);
    },
    [updateUser.pending]: (state) => {
      state.loginLoading = true;
    },
    [updateUser.rejected]: (state, { payload }) => {
      // destructure errors from payload since payload can be an object with keys errors and isSuccess
      state.errors = payload;
      state.loginLoading = false;
      state.isError = true;
      state.message =
        payload?.message ??
        "Unexpected error while trying to update account details.";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState, resetState, getLoggedInUser } = loginSlice.actions;
export default loginSlice.reducer;
