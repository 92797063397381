import { createSlice } from "@reduxjs/toolkit";
import { getOrders } from "../orders/orderActions";
import { getProducts } from "../product/product_table/actions";

export const paginationSlice = createSlice({
  name: "paginate",
  initialState: {
    perPage: 10,
    pageNo: 1,
    pages: [],
    totalDocs: 0,
  },
  reducers: {
    changePage: (state, { payload }) => {
      if (payload === "right") {
        if (Math.ceil(state.totalDocs / state.perPage) === state.pageNo) {
          state.pageNo = 1;
        } else state.pageNo += 1;
      }
      if (payload === "left") {
        if (state.pageNo === 1) {
          state.pageNo = Math.ceil(state.totalDocs / state.perPage);
        } else state.pageNo -= 1;
      }
    },
    setState: (state, { payload }) => {
      return { ...state, ...payload };
    },
    incrementByAmount: (state, action) => {
      state.pageNo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.fulfilled, (state, action) => {
        state.totalDocs = action.payload.total;
        const count = Math.ceil(action.payload.total / state.perPage);
        state.pageCount = count;
        let pages = [];
        for (let i = 1; i <= count; i++) {
          pages.push(i);
        }
        state.pages = pages;
        /* if (count <= 5) {
          state.pages = buildPage(0, count);
        } else {
          if (state.pageNo % 5 === 0)
            state.pages = buildPage(state.pageNo, count);
        } */
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.totalDocs = action.payload.total;
        const count = Math.ceil(action.payload.total / state.perPage);
        state.pageCount = count;
        let pages = [];
        for (let i = 1; i <= count; i++) {
          pages.push(i);
        }
        state.pages = pages;
      });
  },
});

/* const buildPage = (currentPage, end) => {
  let pages = [];
  let count = 0;
  for (let i = 1; i < count; i++) pages.push(currentPage + i);
   if (end > 5 && currentPage + 5 < end) {
    count = 5;
  } else count = end;
  for (let i = 1; i <= count; i++) {
    pages.push(currentPage + i);
  }
  return pages;
}; */
export const { increment, decrement, incrementByAmount, changePage, setState } =
  paginationSlice.actions;

export default paginationSlice.reducer;
