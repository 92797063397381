import { MDBContainer } from "mdb-react-ui-kit";

export default function TermsOfUse() {

    return <MDBContainer>
        <h6>Effective Date: June 29, 2023</h6>
        <p>Please read these Terms of Use ("Terms") carefully before using chomainefoods.com ("the Website"). These Terms govern your access to and use of the Website and its services. By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Website.</p>
        <span>Use of the Website:</span>
        <ol>
            <li>Eligibility: <br />
                You must be at least 18 years old to use the Website. By using the Website, you represent and warrant that you are at least 18 years of age and have the legal capacity to enter into these Terms.</li>
            <li>User Account: <br />
                Some features or services on the Website may require you to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to provide accurate and complete information during the registration process and to update it as necessary. You must notify us immediately of any unauthorized use of your account or any other security breach.</li>
            <li>Prohibited Conduct: <br />
                You agree not to use the Website for any unlawful or prohibited purposes. This includes, but is not limited to, the following:</li>
            <ul>
                <li>Violating any applicable laws or regulations.</li>
                <li>Interfering with the operation of the Website or its services.</li>
                <li>Accessing or using the Website to transmit any harmful or malicious code, viruses, or similar software.</li>
                <li>Engaging in any activity that disrupts or imposes an undue burden on the Website's infrastructure.</li>
                <li>Attempting to gain unauthorized access to any portion of the Website or its systems.</li>
                <li>Using the Website to promote or engage in any illegal, harmful, or fraudulent activities.</li>
            </ul>
            <span>Intellectual Property:</span>
            <ol>
                <li>Ownership: <br />
                    All content, materials, and intellectual property on the Website, including but not limited to text, images, logos, trademarks, and software, are owned or licensed by us and are protected by intellectual property laws. You acknowledge that you have no ownership rights to any such intellectual property.</li>
                <li>Limited License:
                    Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Website for personal, non-commercial purposes. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, content, or materials obtained from the Website without our prior written consent.</li>
            </ol>
            <span>Disclaimer of Warranties:</span>
            <p>The Website and its services are provided on an "as is" and "as available" basis, without warranties of any kind, either express or implied. We do not guarantee the accuracy, completeness, reliability, or availability of the Website or its content. You acknowledge that your use of the Website is at your own risk.</p>
            <span>Limitation of Liability:</span>
            <p>To the fullest extent permitted by applicable law, we shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with your use or inability to use the Website or its services, even if we have been advised of the possibility of such damages.</p>
            <span>Indemnification:</span>
            <p>You agree to indemnify and hold us harmless from any claims, damages, liabilities, costs, or expenses (including reasonable attorneys' fees) arising out of or related to your use of the Website, violation of these Terms, or infringement of any intellectual property or other rights of any person or entity.</p>
            <span>Modifications to the Terms:</span>
            <p>We reserve the right to modify or update these Terms at any time without prior notice. Any changes will be effective immediately upon posting the updated Terms on the Website.</p>
        </ol>
    </MDBContainer>
}