import isEmpty from "is-empty";

export default function getSimilarItems(arr1, arr2) {
  let commonElements = [];
  if (arr1.length === arr2.length) {
    commonElements = arr1.filter((element) => {
      return arr2.find((elem) => elem.product._id === element.product._id);
    });
  } else {
    let longArray = [];
    let shortArray = [];
    if (arr1.length > arr2.length) {
      longArray = arr1;
      shortArray = arr2;
    } else {
      longArray = arr2;
      shortArray = arr1;
    }
    commonElements = longArray.filter((element) => {
      return shortArray.find(
        (elem) => elem.product._id === element.product._id
      );
    });
  }

  return commonElements;
}

export const getUnsimilarItems = (arr1, arr2) => {
  let commonElements = [];
  if (arr1.length === arr2.length) {
    commonElements = arr1.filter((element) => {
      return !arr2.find((elem) => elem.product._id === element.product._id);
    });
  } else {
    let longArray = [];
    let shortArray = [];
    if (arr1.length > arr2.length) {
      longArray = arr1;
      shortArray = arr2;
    } else {
      longArray = arr2;
      shortArray = arr1;
    }
    commonElements = longArray.filter((element) => {
      return !shortArray.find(
        (elem) => elem.product._id === element.product._id
      );
    });
  }
  return commonElements;
};

export const handleSimilarItems = (longArray, similarItems) => {
  let returnArr = [];
  longArray.forEach((item) => {
    const similarItem = similarItems.find(
      (element) => element.product._id === item.product._id
    );
    if (!isEmpty(similarItem)) {
      const updateItem = {
        ...item,
        quantity: parseFloat(similarItem.quantity) + parseFloat(item.quantity),
      };
      returnArr.push(updateItem);
    }
  });
  return returnArr;
};
