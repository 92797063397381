import { configureStore, combineReducers } from "@reduxjs/toolkit";
import registerSlice from "../features/auth/register/registerSlice";
import loginSlice from "../features/auth/login/loginSlice";
import updateSlice from "../features/auth/update_password/updateSlice";
import addSlice from "../features/product/add_product/addSlice";
import categorySlice from "../features/categories/categorySlice";
import modalSlice from "../features/modal";
import productUpdateSlice from "../features/product/product_table/updateSlice";
import basketSlice from "../features/basket/basketSlice";
import orderSlice from "../features/orders/orderSlice";
import paginationSlice from "../features/pagination/paginationSlice";
import resetAccountSlice from "../features/auth/reset_account/resetAccountSlice";
import dashboardSlice from "../features/admin/dashboard/dashboardSlice";
import alertSlice from "../features/product/productAlert/alertSlice";
import  usersSlice  from "../features/admin/users/userSlice";
import promotionSlice from "../features/promotions/promotionSlice";
const auth = combineReducers({
  register: registerSlice,
  login: loginSlice,
  update: updateSlice,
  reset: resetAccountSlice,
});

const product = combineReducers({
  addProduct: addSlice,
  update: productUpdateSlice,
});
export const store = configureStore({
  reducer: {
    auth,
    product,
    categories: categorySlice,
    modal: modalSlice,
    basket: basketSlice,
    orders: orderSlice,
    pagination: paginationSlice,
    dashboard: dashboardSlice,
    alert: alertSlice,
    users: usersSlice,
    promotions: promotionSlice
  },
});
