import { createSlice } from "@reduxjs/toolkit";
import { createOrder, getOrder, getOrders, updateOrder } from "./orderActions";
export const orderSlice = createSlice({
  name: "orders",
  initialState: {
    order: {
      userDetails: {
        address: {
          lineOne: "",
          lineTwo: "",
          city: "",
          parish: "",
        },
      },
      orderTotal: 0,
    },
    orders: [],
    orderLoading: false,
    errors: {},
    isSuccess: false,
    isError: false,
    message: "",
    dispatcher: "",
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: (state) => {
      state.errors = {};
      state.order.userDetails = {
        address: {
          lineOne: "",
          lineTwo: "",
          city: "",
          parish: "",
        },
      };
      state.order = {};
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: {
    [createOrder.fulfilled]: (state, { payload }) => {
      state.order = {
        ...payload.order,
        paymentMethod: {
          value: payload?.order.paymentMethod,
          label: payload.order.paymentMethod,
        },
        paymentStatus: {
          value: payload.order.paymentStatus,
          label: payload.order.paymentStatus,
        },
      };
      state.orderLoading = false;
      state.message = payload.message;
      state.isSuccess = true;
      state.isError = false;
      state.errors = {};
      state.dispatcher = "";
    },
    [createOrder.pending]: (state) => {
      state.orderLoading = true;
    },
    [createOrder.rejected]: (state, { payload }) => {
      state.orderLoading = false;
      state.errors = payload.errors ?? payload;
      state.message =
        payload?.message ?? "Unexpected error while creating order.";
      state.isError = true;
      state.dispatcher = "createOrder";
    },
    [getOrder.fulfilled]: (state, { payload }) => {
      state.order = {
        ...payload,
      };
      state.orderLoading = false;
      state.message = payload.message;
      state.isSuccess = true;
    },
    [getOrder.pending]: (state) => {
      state.orderLoading = true;
    },
    [getOrder.rejected]: (state, { payload }) => {
      state.orderLoading = false;
      state.errors = payload?.errors ?? payload;
      state.isError = true;
    },
    [getOrders.fulfilled]: (state, { payload }) => {
      state.orders = payload.orders;
      state.orderLoading = false;
      state.isSuccess = true;
      state.dispatcher = "get";
    },
    [getOrders.pending]: (state) => {
      state.orderLoading = true;
    },
    [getOrders.rejected]: (state, { payload }) => {
      state.orderLoading = false;
      state.errors = payload?.errors ?? payload;
      state.isError = true;
      state.orders = [];
    },
    [updateOrder.fulfilled]: (state, { payload }) => {
      const index = state.orders.findIndex((x) => x._id === payload.order._id);
      state.orders[index] = { ...payload.order };
      state.order = {
        ...payload.order,
        status: {
          value: payload?.order?.status,
          label: payload?.order?.status,
        },
        paymentStatus: {
          value: payload?.order?.paymentStatus,
          label: payload?.order?.paymentStatus,
        },
      };
      state.orderLoading = false;
      state.message = payload?.message;
      state.isSuccess = true;
      state.dispatcher = "update";
      state.isError = false;
      state.errors = {};
    },
    [updateOrder.pending]: (state) => {
      state.orderLoading = true;
    },
    [updateOrder.rejected]: (state, { payload }) => {
      state.orderLoading = false;
      state.errors = payload;
      state.isError = true;
      state.message = payload?.message;
      state.isSuccess = false
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState, resetState } = orderSlice.actions;
export default orderSlice.reducer;
