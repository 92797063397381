import { createSlice } from "@reduxjs/toolkit";
import {
  updateProduct,
  getProduct,
  getProducts,
  getUserProducts,
  deleteProduct,
} from "./actions";
export const updateSlice = createSlice({
  name: "updateproduct",
  initialState: {
    products: [],
    errors: {},
    updateLoading: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: (state) => {
      state.errors = {};
      state.updateLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: {
    [getProducts.fulfilled]: (state, action) => {
      state.updateLoading = false;
      state.products = action.payload.products;
    },
    [getProducts.pending]: (state) => {
      state.updateLoading = true;
    },
    [getProducts.rejected]: (state, { payload }) => {
      state.updateLoading = false;
      //state.errors = payload;
    },
    [getUserProducts.fulfilled]: (state, action) => {
      state.updateLoading = false;
      state.products = action.payload.products;
    },
    [getUserProducts.pending]: (state) => {
      state.updateLoading = true;
    },
    [getUserProducts.rejected]: (state, { payload }) => {
      state.updateLoading = false;
      //state.errors = payload;
    },
    [deleteProduct.fulfilled]: (state, { payload }) => {
      state.updateLoading = false;
      state.products = state.products.filter((x) => x._id !== payload.id);
      state.isSuccess = true;
      state.message = payload.data.message ?? "Product deleted successfully!";
      state.dispatcher = "deleteproduct";
    },
    [deleteProduct.pending]: (state) => {
      state.updateLoading = true;
    },
    [deleteProduct.rejected]: (state, { payload }) => {
      state.updateLoading = false;
      state.errors = payload;
      state.isError = true;
      state.dispatcher = "deleteproduct";
    },
    [updateProduct.fulfilled]: (state, { payload }) => {
      state.updateLoading = false;
      const index = state.products.findIndex((x) => x._id === payload._id);
      state.products[index] = { ...payload };
      state.isSuccess = true;
      state.dispatcher = "updateproduct";
    },
    [updateProduct.pending]: (state) => {
      state.updateLoading = true;
    },
    [updateProduct.rejected]: (state, { payload }) => {
      state.updateLoading = false;
      state.errors = payload;
      state.deleteError = true;
      state.message = payload?.message ?? "Your access token has expired.";
      state.dispatcher = "updateproduct";
    },
    [getProduct.fulfilled]: (state, { payload }) => {
      state.product = payload;
      state.updateLoading = false;
    },
    [getProduct.pending]: (state) => {
      state.updateLoading = true;
    },
    [getProduct.rejected]: (state, { payload }) => {
      state.updateLoading = false;
      state.errors = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState, resetState } = updateSlice.actions;
export default updateSlice.reducer;
