import { createSlice } from "@reduxjs/toolkit";
import {
  acceptOffer,
  createPromotion,
  deletePromotion,
  getPromo,
  getPromotions,
  updatePromotion,
} from "./promActions";
export const promotionSlice = createSlice({
  name: "promotions",
  initialState: {
    promotions: [],
    promotion: {
      isActive: false,
    },
    promotionsLoading: false,
    isSuccess: false,
    message: "",
    isError: false,
    errors: {},
    dispatcher: "",
    isEdit: false,
    editId: "",
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: (state) => {
      state.message = "";
      state.errors = {};
      state.isSuccess = false;
      state.promotion = {};
      state.isError = false;
      state.promotionsLoading = false;
      state.editId = "";
      state.isEdit = false
    },
  },
  extraReducers: {
    [createPromotion.fulfilled]: (state, { payload }) => {
      state.promotionsLoading = false;
      state.errors = {};
      state.isSuccess = true;
      state.promotions.push(payload);
      state.message = "Promotion created successfully";
      state.dispatcher = "create";
    },
    [createPromotion.pending]: (state) => {
      state.promotionsLoading = true;
    },
    [createPromotion.rejected]: (state, { payload }) => {
      state.promotionsLoading = false;
      state.errors = payload?.errors || payload;
      state.message =
        payload?.message || "Unexpected Error creating promotion.";
      state.isError = true;
    },
    [getPromotions.fulfilled]: (state, { payload }) => {
      state.promotions = payload.promotions;
      state.promotionsLoading = false;
      state.isSuccess = true;
      state.dispatcher = "get";
    },
    [getPromotions.pending]: (state) => {
      state.promotionsLoading = true;
    },
    [getPromotions.rejected]: (state, action) => {
      state.promotionsLoading = false;
      state.dispatcher = "get";
    },
    [deletePromotion.fulfilled]: (state, { payload }) => {
      state.promotionsLoading = false;
      state.isSuccess = true;
      state.message = payload.data;
      state.promotions = state.promotions.filter((x) => x._id !== payload.id);
      state.dispatcher = "delete";
    },
    [deletePromotion.pending]: (state) => {
      state.promotionsLoading = true;
    },
    [deletePromotion.rejected]: (state, { payload }) => {
      state.promotionsLoading = false;
      state.message = payload?.message || "Error fetching promotions";
      state.isSuccess = false;
      state.isError = true;
      state.dispatcher = "delete";
    },
    [updatePromotion.fulfilled]: (state, { payload }) => {
      state.promotionsLoading = false;
      state.isSuccess = true;
      const index = state.categories.findIndex(
        (category) => category._id === payload._id
      );
      state.promotions[index] = payload;
      state.message = "Promotion Updated Successfully";
      state.dispatcher = "update";
      state.isEdit = false;
    },
    [updatePromotion.pending]: (state) => {
      state.promotionsLoading = true;
    },
    [updatePromotion.rejected]: (state, { payload }) => {
      state.promotionsLoading = false;
      state.isError = true;
      state.message = payload?.message || "Error updating promotion.";
      state.errors = payload;
      state.dispatcher = "update";
    },
    [acceptOffer.fulfilled]: (state, { payload }) => {
      state.promotionsLoading = false;
      state.isSuccess = true;
      state.message = payload;
      state.dispatcher = "acceptoffer";
    },
    [acceptOffer.pending]: (state) => {
      state.promotionsLoading = true;
    },
    [acceptOffer.rejected]: (state, { payload }) => {
      state.promotionsLoading = false;
      state.isError = true;
      state.message =
        payload?.message ||
        "Error trying to use this promotion. Please try again later.";
      state.errors = payload;
      state.dispatcher = "acceptoffer";
    },
    [getPromo.fulfilled]: (state, { payload }) => {
      state.message = "Discount applied";
      state.promotion = payload;
      state.promotionsLoading = false;
      state.dispatcher = "getpromo";
      state.isSuccess = true;
    },
    [getPromo.pending]: (state) => {
      state.promotionsLoading = true;
    },
    [getPromo.rejected]: (state, { payload }) => {
      state.promotionsLoading = false;
      state.message = payload.message || "Invalid Code.";
      state.dispatcher = "getpromo";
    },
  },
});

export const { resetState, setState } = promotionSlice.actions;
export default promotionSlice.reducer;
