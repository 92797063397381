import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
export default function AddToBasket({ product }) {
  const dispatch = useDispatch();
  return (
    <>
      <MDBBtn
        onClick={() => {
          import("./basketActions").then((ba) =>
            dispatch(ba.addToBasket(product))
              .unwrap()
              .then((data) => {
                toast.success(`${data?.name} added to basket!`, {
                  toastId: "product-added-to-basket",
                });
              })
              .catch((err) => {
                throw err;
              })
              .catch((err) =>
                toast.error(`${err} Could Not add to basket!`, {
                  toastId: "product-not-added-to-basket",
                })
              )
          );
        }}
        disabled={!product?.quantity}
      >
        {!product?.quantity ? "Unavailable" : "Add to Basket"}
      </MDBBtn>
    </>
  );
}
