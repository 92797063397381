import React from "react";
import './index.css'
const PageHeader = ({ title, content }) => {
  return (
    <section className="s-wrapper">
      <div className="h-content">
        <h3 className="section-header">{title}</h3>
        <p>{content}</p>
      </div>
    </section>
  );
};
export default PageHeader;
