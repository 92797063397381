import { createSlice } from "@reduxjs/toolkit";
import { getUsers, getMailingList } from "./userActions";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    mailingList: [],
    selectedUser: {},
    usersLoading: false
  },
  reducers: {
    setState: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.users
        state.usersLoading = false
      })
      .addCase(getUsers.pending, (state) => {
        state.usersLoading = true
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.errors = action.payload
        state.usersLoading = false
      }).addCase(getMailingList.fulfilled, (state, action) => {
        state.mailingList = action.payload
        state.usersLoading = false
      })
      .addCase(getMailingList.pending, (state) => {
        state.usersLoading = true
      })
      .addCase(getMailingList.rejected, (state, action) => {
        state.errors = action.payload
        state.usersLoading = false
      });
  },
});

export const { setState } =
  usersSlice.actions;

export default usersSlice.reducer;
