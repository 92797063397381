import { createSlice } from "@reduxjs/toolkit";
import updateActions from "./updateActions";
export const updateSlice = createSlice({
  name: "update",
  initialState: {
    password: "",
    confirmPassword: "",
    updateLoading: false,
    errors: {},
    isSuccess: false,
    isError: false,
    message: "",
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: (state) => {
      state.password = "";
      state.confirmPassword = "";
      state.errors = {};
      state.updateLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [updateActions.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.message = payload.message ?? "We received your email"
      state.updateLoading = false;
      state.errors = {};
      state.isError = false;
    },
    [updateActions.pending]: (state) => {
      state.updateLoading = true;
    },
    [updateActions.rejected]: (state, { payload }) => {
      // destructure errors from payload since payload can be an object with keys errors and isSuccess
      const { errors } = payload;
      state.errors = errors || payload;
      state.updateLoading = false;
      state.isError = true;
      state.message = payload.message ?? "Check credentials.";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState, resetState } = updateSlice.actions;
export default updateSlice.reducer;
