import {
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePromotion, getPromotions } from "../../promotions/promActions";
import { setState, resetState } from "../../promotions/promotionSlice";
const AddPromotion = React.lazy(() => import("./AddPromotion"));
const PageHeader = React.lazy(() => import("../page_header"));

const AdminPromotionPage = () => {
  const { promotions, isEdit, editId } = useSelector(
    (state) => state.promotions
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPromotions());
  }, [dispatch]);

  const setEditData = (data) => {
    if (isEdit && editId === data._id) {
      dispatch(resetState());
    } else {
      const {
        imageUrl,
        title,
        percentOff,
        numberOfUses,
        content,
        startDate,
        endDate,
        storeWide,
        requiresEmail,
        isActive,
      } = data;
      dispatch(
        setState({
          isEdit: true,
          editId: data._id,
          promotion: {
            imageUrl,
            title,
            percentOff,
            numberOfUses,
            content,
            startDate: startDate.split("T")[0],
            endDate: endDate.split("T")[0],
            storeWide,
            requiresEmail,
            isActive,
          },
        })
      );
    }
  };
  return (
    <>
      <PageHeader title={"Promotions"} />
      <section>
        <AddPromotion />

        <MDBTable bordered className="bg-light mt-5" responsive>
          <MDBTableHead>
            <tr>
              <th>Name</th>
              <th>Details</th>
              <th>Options</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {promotions.map((promotion) => (
              <tr key={promotion._id}>
                <td>{promotion.title}</td>
                <td>{promotion.content}</td>
                <td>
                  <MDBIcon onClick={() => setEditData(promotion)} icon="edit" />
                  <MDBIcon
                    onClick={() => dispatch(deletePromotion(promotion._id))}
                    icon="trash"
                  />
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </section>
    </>
  );
};
export default AdminPromotionPage;
