import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../page_header";
import { useEffect } from "react";
import { getMailingList } from "./userActions";
import { downloadMailingCsv } from "../../promotions/promActions";

const MailingList = () => {
  const { mailingList } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMailingList());
  }, [dispatch]);
  return (
    <MDBTable bordered responsive>
      <MDBTableHead>
        <tr>
          <th scope="col">
            User{" "}
            <span style={{ cursor: "pointer" }} className="float-end">
              <MDBIcon
                onClick={() =>
                  dispatch(
                    downloadMailingCsv({
                      fileTitle: "Mailing List",
                    })
                  )
                }
                icon="download"
              />
            </span>
          </th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {mailingList.length ? (
          mailingList.map((user) => (
            <tr key={user}>
              <td className="text-start">{user}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5}>No Users On Mailing List</td>
          </tr>
        )}
      </MDBTableBody>
    </MDBTable>
  );
};

const AdminMailingList = () => {
  return (
    <>
      <PageHeader
        title={"Mailing List"}
        content={"All Users who signed up for a promotion."}
      />
      <section>
        <h3>Mailing List</h3>
        <div className="w-50 m-auto bg-light">
          <MailingList />
        </div>
      </section>
    </>
  );
};

export default AdminMailingList;
