import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import isEmpty from "is-empty";
import { getLoggedInUser } from "../../auth/login/loginSlice";
import baseUrl from "../../../api-config";
import { setAuthToken } from "../../../utils/setAuthToken";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async ({ status = null, perPage = 10, pageNo = 1 }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getLoggedInUser());
      const { startDate, endDate } = thunkAPI.getState().dashboard;
      const { user } = thunkAPI.getState().auth.login;
      const url = `${baseUrl}/user?perPage=${perPage}&pageNo=${pageNo}`;
      let query = "";
      if (isEmpty(user) || user.role[0] === "admin") {
        query = status ? `&status=${status}` : url;
      } else {
        query = status
          ? `&status=${status}&userId=${user.id}`
          : `&userId=${user.id}`;
      }

      if (startDate && endDate) {
        query = `${query}&startDate=${startDate}&endDate=${endDate}`;
      } else if (startDate) query = `${query}&startDate=${startDate}`;
      setAuthToken(axios);
      const response = await axios.get(
        `${url === query ? query : `${url}${query}`}`
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data ?? error.response);
    }
  }
);

export const getMailingList = createAsyncThunk(
  "users/mailingList",
  async (args, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}/promotions/mailing-list`);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {}
  }
);
