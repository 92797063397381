import { createSlice } from "@reduxjs/toolkit";
import { deleteProduct, updateProduct } from "../product_table/actions";
import { createProduct } from "../add_product/addActions";
export const alertSlice = createSlice({
  name: "productAlert",
  initialState: {
    errors: {},
    alertLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    dispatcher: "",
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    resetState: (state) => {
      state.alertLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [createProduct.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.message = payload.message ?? "Product added";
      state.alertLoading = false;
    },
    [createProduct.pending]: (state) => {
      state.alertLoading = true;
    },
    [createProduct.rejected]: (state, { payload }) => {
      state.alertLoading = false;
      state.isError = true;
      state.message = payload?.message ?? "Error adding product";
    },
    [updateProduct.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.message = payload.message ?? "Product Updated Successfully.";
      state.alertLoading = false;
    },
    [updateProduct.rejected]: (state, { payload }) => {
      state.errors = payload.errors ?? payload;
      state.alertLoading = false;
      state.isError = true;
      state.message = payload?.message ?? "Error updating product";
    },
    [deleteProduct.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.message = payload?.data ? payload?.data.message : payload?.message ? payload.message : "Product Deleted Successfully.";
      state.alertLoading = false;
    },
    [deleteProduct.rejected]: (state, { payload }) => {
      state.errors = payload.errors ?? payload;
      state.alertLoading = false;
      state.isError = true;
      state.message = payload?.data ? payload?.data.message : payload?.message ? payload.message : "Error Deleting Product.";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setState, resetState } = alertSlice.actions;
export default alertSlice.reducer;
