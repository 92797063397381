import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { validateProduct } from "../../../utils/product";
import { setAuthToken } from "../../../utils/setAuthToken";
import baseUrl from "../../../api-config";
import { getLoggedInUser } from "../../auth/login/loginSlice";
import isEmpty from "is-empty";
export const createProduct = createAsyncThunk(
  "product/createProduct",
  async (data, thunkAPI) => {
    try {
      const product = {
        name: data.get("name"),
        quantity: data.get("quantity"),
        price: data.get("price"),
        unit: data.get("unit"),
        description: data.get("description"),
        category: data.get("category"),
        subCategories: data.get("subcategories"),
        imageUrl: data.get("imageUrl"),
        tags: data.get("tags"),
        lowCount: data.get("lowCount")
      };

      const validProduct = validateProduct(product);
      if (validProduct.success) {
        thunkAPI.dispatch(getLoggedInUser())
        const { user } = thunkAPI.getState().auth.login
        setAuthToken(axios);
        if (isEmpty(user))
          return thunkAPI.rejectWithValue({ status: 401, message: "Session timed out. Relogin to carry out this action." })
        const response = await axios.post(`${baseUrl}/product`, data);
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } else return thunkAPI.rejectWithValue(validProduct);
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data ?? error);
    }
  }
);
