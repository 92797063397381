import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setAuthToken } from "../../utils/setAuthToken";
import baseUrl from "../../api-config";
import validatePromotion, { validateAcceptOffer } from "../../utils/promotion";
export const createPromotion = createAsyncThunk(
  "promotion/createPromotion",
  async (data, thunkAPI) => {
    try {
      let promotion = {
        title: data.get("title"),
        numberOfUses: data.get("numberOfUses"),
        content: data.get("content"),
        startDate: data.get("startDate"),
        endDate: data.get("endDate"),
        storeWide: data.get("storeWide"),
        requiresEmail: data.get("requiresEmail"),
        isActive: data.get("isActive"),
        percentOff: data.get("percentOff"),
      };

      let validPromotion = validatePromotion(promotion);

      if (validPromotion.success) {
        setAuthToken(axios);
        const response = await axios.post(`${baseUrl}/promotions`, data);
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } else return thunkAPI.rejectWithValue(validPromotion.errors);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPromotions = createAsyncThunk(
  "promotion/getPromotions",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}/promotions`);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deletePromotion = createAsyncThunk(
  "promotion/deletePromotion",
  async (id, thunkAPI) => {
    try {
      setAuthToken(axios);
      const response = await axios.delete(`${baseUrl}/promotions/${id}`);
      if (response.status === 200) {
        return { data: response.data, id };
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updatePromotion = createAsyncThunk(
  "promotion/updatePromotion",
  async (data, thunkAPI) => {
    let promotion = {
      ...data,
    };

    const validPromotion = validatePromotion(promotion);
    if (validPromotion.success) {
      try {
        setAuthToken(axios);
        const response = await axios.put(
          `${baseUrl}/promotions/${data.id}`,
          promotion
        );
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    } else return thunkAPI.rejectWithValue(validPromotion.errors);
  }
);

export const acceptOffer = createAsyncThunk(
  "promotion/acceptOffer",
  async (data, thunkAPI) => {
    let promotion = {
      id: data?.promotion?._id,
      email: data?.email,
    };

    const validAcceptance = validateAcceptOffer(promotion);

    if (validAcceptance.success) {
      try {
        //setAuthToken(axios);
        const response = await axios.post(
          `${baseUrl}/promotions/${promotion.id}`,
          data
        );
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    } else return thunkAPI.rejectWithValue(validAcceptance.errors);
  }
);

export const getPromo = createAsyncThunk(
  "promotion/getPromo",
  async ({ email, promoCode }, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}/promotions/redeem`, {
        email,
        promoCode,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const downloadMailingCsv = createAsyncThunk(
  "promotions/downloadMailingList",
  async ({ fileTitle }, thunkAPI) => {
    
    const response = await axios.get(`${baseUrl}/promotion/download`);

    if (response.status === 200) {
      const blob = new Blob([response.data.csvFile], { type: "text/csv" });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);

      // Creating an anchor(a) tag of HTML
      const a = document.createElement("a");

      // Passing the blob downloading url
      a.setAttribute("href", url);

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute("download", `${fileTitle}.csv`);

      // Performing a download with click
      a.click();
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
