import { createSlice } from "@reduxjs/toolkit";
import { getDetails, getOrderedProducts } from "./dashboardActions";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    allOrders: {},
    submittedOrders: {},
    packedOrders: {},
    deliveredOrders: {},
    orderedProducts: [],
    products: {},
    startDate: "",
    endDate: "",
    errors: {},
    dashboardLoading: false
  },
  reducers: {
    setState: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {
    [getDetails.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        submittedOrders: payload?.orderDetails?.submittedOrders,
        packedOrders: payload?.orderDetails?.packedOrders,
        deliveredOrders: payload?.orderDetails?.deliveredOrders,
        allOrders: payload?.orderDetails?.allOrders,
        products: { ...payload.productDetails },
        dashboardLoading: false
      };
    },
    [getDetails.pending]: (state) => {
      state.dashboardLoading = true
    },
    [getDetails.rejected]: (state, { payload }) => {
      return {
        ...state,
        errors: { ...payload },
        dashboardLoading: false
      };
    },
    [getOrderedProducts.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        orderedProducts: payload.products,
      };
    },
    [getOrderedProducts.rejected]: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
});
export const { setState } = dashboardSlice.actions;
export default dashboardSlice.reducer;
