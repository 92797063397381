import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ValidateEmail from "../../../utils/user";
import baseUrl from "../../../api-config"
const resetAccountActions = {
  resendVerification: createAsyncThunk(
    "auth/resendVerification",
    async (data, thunkAPI) => {
      try {
        const userCredentials = {
          ...data,
        };
        const validUser = ValidateEmail(userCredentials.email);
        if (validUser) {
          const response = await axios.post(
            `${baseUrl}/auth/resend`,
            userCredentials
          );
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response.data);
          }
        } else return thunkAPI.rejectWithValue(validUser);
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  ),
  recoverPassword: createAsyncThunk("auth/forgot", async (data, thunkAPI) => {
    try {
      const userCredentials = {
        ...data,
      };
      const validUser = ValidateEmail(userCredentials.email);
      if (validUser) {
        const response = await axios.post(`${baseUrl}/auth/recover`, userCredentials);
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } else return thunkAPI.rejectWithValue(validUser);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }),
};

export default resetAccountActions;
