import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import TermsOfUse from "./TermsOfUse";
import RefundPolicy from "./RefundPolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import { useDispatch, useSelector } from "react-redux";
import { resetState, togglePointer } from "../modal";

export default function Legal() {
    const { pointerName, pointer } = useSelector(state => state.modal)
    const dispatch = useDispatch()
    const getName = (name) => name === "refund" ? "Refund Policy" : name === "privacy" ? "Privacy Policy" : name === "terms" ? "Terms Of Use" : ""
    return <MDBModal staticBackdrop show={pointer} setShow={togglePointer}>
        <MDBModalDialog className="modal-lg">
            <MDBModalContent className="text-start">
                <MDBModalHeader>
                    <MDBModalTitle>{getName(pointerName)}</MDBModalTitle>
                    <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={() => dispatch(resetState())}
                    ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                    {pointerName === "terms" && <TermsOfUse />}
                    {pointerName === "privacy" && <PrivacyPolicy />}
                    {pointerName === "refund" && <RefundPolicy />}
                </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
}