import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { validateProduct } from "../../../utils/product";
import { setAuthToken } from "../../../utils/setAuthToken";
import baseUrl from "../../../api-config";
import { getLoggedInUser } from "../../auth/login/loginSlice";
import isEmpty from "is-empty";
export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (data, thunkAPI) => {
    try {
      let product = {
        _id: data.get("_id"),
        name: data.get("name"),
        description: data.get("description"),
        price: data.get("price"),
        category: data.get("category"),
        unit: data.get("unit"),
        quantity: data.get("quantity"),
      };
      let validProduct = validateProduct(product);
      if (validProduct.success) {
        thunkAPI.dispatch(getLoggedInUser());
        const { user } = thunkAPI.getState().auth.login;
        setAuthToken(axios);
        if (isEmpty(user))
          return thunkAPI.rejectWithValue({
            status: 401,
            message: "Session timed out. Relogin to carry out this action.",
          });
        setAuthToken(axios);
        const response = await axios.put(
          `${baseUrl}/product/${product._id}`,
          data
        );
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } else return thunkAPI.rejectWithValue(validProduct);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data ?? error);
    }
  }
);

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async ({ category = null, perPage = null, pageNo = null }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getLoggedInUser());
      let url = "";
      if (category && perPage && pageNo) {
        url = `${baseUrl}/product?category=${category}&perPage=${perPage}&pageNo=${pageNo}`;
      } else if (category) {
        url = `${baseUrl}/product?category=${category}`;
      } else if (perPage && pageNo)
        url = `${baseUrl}/product?perPage=${perPage}&pageNo=${pageNo}`;
      else url = `${baseUrl}/product`;
      let response = await axios.get(url);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      console.log(error, "is the error");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getUserProducts = createAsyncThunk(
  "product/getUserProducts",
  async (category = null, thunkAPI) => {
    try {
      let url = "";
      if (category) {
        url = `${baseUrl}/product/user-products?category=${category}`;
      } else url = `${baseUrl}/product/user-products`;
      let response = await axios.get(url);
      if (response.status === 200) {
        const { data } = response;
        const unavailableProducts = data.products.filter(
          (x) => x.quantity === 0
        );
        const availableProducts = data.products.filter((x) => x.quantity);
        const returnObject = {
          total: data.total,
          products: [...availableProducts, ...unavailableProducts],
        };
        return returnObject;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      console.log(error, "is the error");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProduct = createAsyncThunk(
  "product/getProduct",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}/product/${id}`);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(getLoggedInUser());
      const { user } = thunkAPI.getState().auth.login;
      setAuthToken(axios);
      if (isEmpty(user))
        return thunkAPI.rejectWithValue({
          status: 401,
          message: "Session timed out. Relogin to carry out this action.",
        });
      setAuthToken(axios);
      const response = await axios.delete(`${baseUrl}/product/${id}`);
      if (response.status === 200) {
        return { data: response.data, id };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const downloadProductCsv = createAsyncThunk(
  "product/downloadProducts",
  async ({ category, fileTitle }, thunkAPI) => {
    const { startDate, endDate } = thunkAPI.getState().dashboard;
    const response = await axios.get(`${baseUrl}/product/download`, {
      params: {
        startDate,
        endDate,
        category: category ?? null,
      },
    });

    if (response.status === 200) {
      const blob = new Blob([response.data.csvFile], { type: "text/csv" });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);

      // Creating an anchor(a) tag of HTML
      const a = document.createElement("a");

      // Passing the blob downloading url
      a.setAttribute("href", url);

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute("download", `${fileTitle}.csv`);

      // Performing a download with click
      a.click();
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);
