import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isUser } from "../../../utils/user";
import baseUrl from '../../../api-config'
const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (data, thunkAPI) => {
    try {
      const user = {
        ...data,
      };

      const validUser = isUser(user);

      if (validUser.success) {
        const response = await axios.post(`${baseUrl}/auth/register`, user);
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } else return thunkAPI.rejectWithValue(validUser);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export default registerUser;
