import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl from "../../../api-config";
export const getDetails = createAsyncThunk(
  "dashboard/getDetails",
  async (args, thunkAPI) => {
    try {
      const { startDate, endDate } = thunkAPI.getState().dashboard;
      const response = await axios.get(`${baseUrl}/dashboard`, {
        params: { startDate, endDate },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getOrderedProducts = createAsyncThunk(
  "dashboard/getOrderedProducts",
  async (args = null, thunkAPI) => {
    try {
      const { startDate, endDate } = thunkAPI.getState().dashboard;
      const response = await axios.get(`${baseUrl}/dashboard/todays-products`, {
        params: { startDate, endDate, status: args },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
