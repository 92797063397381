import React from "react";
const UserList = React.lazy(() => import("../../tabs"));
const PageHeader = React.lazy(() => import("../page_header"));
const statusList = [
  { label: "All", value: 1 },
  { label: "Verified", value: "verified" },
  { label: "Not Verified", value: "not-verified" },
 /*  { label: "PACKED", value: "PACKED" },
  { label: "DELIVERED", value: "DELIVERED" },
  { label: "CANCELLED", value: "CANCELLED" }, */
];
const AdminUsersPage = () => {
  return (
    <>
      <PageHeader title={"Users"} content={"All Users on the system."} />
      <section>
        <h3>User List</h3>
        <UserList type={"users"} data={statusList} />
      </section>
    </>
  );
};
export default AdminUsersPage;
