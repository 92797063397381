import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setAuthToken } from "../../../utils/setAuthToken";
import { isUser, ValidatePasswords } from "../../../utils/user";
import baseUrl from "../../../api-config";
export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async ({ id, data }, thunkAPI) => {

    try {
      let validPassword = {};
      let update = true;
      if (data.password) {
        validPassword = ValidatePasswords(data);
        if (validPassword.errors) {
          update = false;
        }
      }
      const validUser = isUser(data, "update");
      if (validUser.success && update) {
        const { firstName, lastName, mobilePhone, password, address } = data;
        const user = { firstName, lastName, mobilePhone, password, address };
        setAuthToken(axios);
        let response = await axios.put(`${baseUrl}/user/${id}`, user);
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response.data);
        }
      } else {
        return thunkAPI.rejectWithValue({
          ...validPassword?.errors,
          ...validUser?.errors,
        });
      }
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error);
    }

  }
);
