import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
} from "mdb-react-ui-kit";
import React from "react";
import OrderHistory from "../../tabs";
const statusList = [
  { label: "All", value: 1 },
  { label: "SUBMITTED", value: "SUBMITTED" },
  { label: "PACKED", value: "PACKED" },
  { label: "DELIVERED", value: "DELIVERED" },
  { label: "CANCELLED", value: "CANCELLED" },
];
function Orders() {
  return (
    <div className="p-3 page-wrapper d-flex justify-content-center align-items-center">
      <MDBContainer>
        <div className="border border-3 border-primary"></div>
        <MDBCard>
          <MDBCardBody className="p-3">
            <h2 className="fw-bold mb-2 text-uppercase">Your Orders</h2>
            <OrderHistory
              data={statusList}
              type={"orderHistory"}
              key="order-history"
            />
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}

export default Orders;
