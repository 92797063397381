import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
const ProductCard = React.lazy(() => import("./productsList/productCard"));
export default function ProductCarousel({ category = null }) {
  const { products, product } = useSelector((state) => state.product.update);
  const dispatch = useDispatch();

  useEffect(() => {
    if (category) {
      import("./product_table/actions")
        .then((pa) => dispatch(pa.getUserProducts(category)))
        .catch((err) => console.log(err));
    } else {
      import("./product_table/actions")
        .then((pa) => dispatch(pa.getUserProducts()))
        .catch((err) => console.log(err));
    }
  }, [category, dispatch]);

  const Wrapper = ({ product }) => (
    <motion.div whileHover={{ scale: 0.9 }}>
      <ProductCard
        key={product._id}
        size={{ width: 200, height: 200 }}
        product={product}
      />
    </motion.div>
  );
  return (
    <Carousel
      additionalTransfrom={0}
      arrows={products?.length > 1}
      autoPlaySpeed={3000}
      centerMode={false}
      containerClass="container-with-dots mt-2"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass="align-items-stretch"
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 3,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
      customTransition="all .5"
      autoPlay
    >
      {category
        ? products
            .filter((prod) => prod.name !== product.name)
            .map((product) => <Wrapper key={product} product={product} />)
        : products.map((product) => (
            <Wrapper key={product} product={product} />
          ))}
    </Carousel>
  );
}
