import React, { useEffect } from "react";
import {
  MDBBadge,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function OrderSummary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { order, orderLoading } = useSelector((state) => state.orders);
  useEffect(() => {
    import("./orderActions")
      .then((oa) => dispatch(oa.getOrder(orderId)))
      .catch((err) => console.log(err));
  }, [dispatch, orderId]);
  return (
    <div className="p-3 page-wrapper d-flex justify-content-center align-items-center">
      <MDBContainer>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md={8} lg={6}>
            <div className="border border-3 border-primary"></div>
            <MDBCard className="bg-white shadow-lg">
              <MDBCardBody className="p-5">
                {orderLoading ? (
                  <MDBSpinner color="dark" size={150} />
                ) : (
                  <>
                    <section>
                      <h2 className="fw-bold mb-2 text-uppercase">Chomaine Foods</h2>
                      <p>
                        <b>Thank you for shopping with us.</b> Check your email
                        for order confirmation.
                      </p>
                    </section>
                    <section
                      style={{
                        border: "2px solid grey",
                        borderRadius: 3,
                        marginBottom: 2,
                      }}
                    >
                      <h6>Customer Details</h6>
                      <div className="p-3 m-auto text-start">
                        <div>
                          <b>Name</b>: {order?.userDetails?.firstName}{" "}
                          {order?.userDetails?.lastName}
                        </div>
                        <div>
                          <b>E-mail</b>: {order?.userDetails?.email}
                        </div>
                        <div>
                          <b>Phone Number</b>: {order?.userDetails?.mobilePhone}
                        </div>
                        <div className="d-flex">
                          <b>Address</b>:
                          <div style={{ marginLeft: 2 }} className="text-start">
                            <div>{order.userDetails?.address?.lineOne}</div>
                            <div>{order.userDetails?.address?.lineTwo}</div>
                            <div>{order.userDetails?.address?.city}</div>
                            <div>{order.userDetails?.address?.parish}</div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      style={{ border: "2px solid grey", borderRadius: 3 }}
                    >
                      <h6>Order Information</h6>
                      <div className="p-3 text-start">
                        <div>
                          <b>Payment Method</b>:{" "}
                          {order?.paymentMethod?.value ?? order?.paymentMethod}
                        </div>
                        <b>Basket</b>
                        <MDBListGroup>
                          {order?.items?.map((item, key) => (
                            <MDBListGroupItem
                              key={key}
                              className="d-flex justify-content-between align-items-center"
                            >
                              {item.product.name}
                              <MDBBadge pill>{item.quantity}</MDBBadge>
                            </MDBListGroupItem>
                          ))}
                        </MDBListGroup>
                        <p className="text-start">Notes: {order.notes}</p>
                        <MDBBtn
                          onClick={() => {
                            navigate("/products");
                            import("./orderSlice")
                              .then((oa) => dispatch(oa.resetState()))
                              .catch((err) => console.log(err));
                          }}
                          className="btn-primary"
                          block
                          children={"Continue Shopping"}
                        />
                      </div>
                    </section>
                  </>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default OrderSummary;
