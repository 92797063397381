export const setAuthToken = (axios) => {
  let token = localStorage.getItem("chmtoken");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // Delete auth header
    localStorage.removeItem("chmtoken");
    delete axios.defaults.headers.common["Authorization"];
  }
};
