import React, { useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { useDispatch } from "react-redux";
import { incrementByAmount } from "../pagination/paginationSlice";
const OrderList = React.lazy(() => import("../user/orders/orderList"));
const ProductTable = React.lazy(() => import("../product/product_table"));
const OrderTable = React.lazy(() => import("../orders/orderTable"));
const UserList = React.lazy(() => import("../admin/users/userList"));
export default function Tabs({ type, data }) {
  const [basicActive, setBasicActive] = useState(data[0]);
  const dispatch = useDispatch()
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
    dispatch(incrementByAmount(1))
  };

  return (
    <MDBCard style={{ backgroundColor: "royalblue", margin: "auto" }}>
      <MDBCardBody>
        <MDBTabs style={{ backgroundColor: "azure" }} className="mb-3">
          {data?.map((content) => (
            <MDBTabsItem key={content.label}>
              <MDBTabsLink
                onClick={() => handleBasicClick(content)}
                active={basicActive === content}
              >
                {content.label}
              </MDBTabsLink>
            </MDBTabsItem>
          ))}
        </MDBTabs>
        <MDBTabsContent>
          {data.map((content) => (
            <MDBTabsPane key={content.value} show={basicActive === content}>
              {type === "orders" ? (
                <OrderTable
                  active={basicActive === content}
                  filterBy={content.value}
                />
              ) : type === "orderHistory" ? (
                <OrderList
                  active={basicActive === content}
                  filterBy={content.value}
                />
              ) : type === "users" ? <UserList active={basicActive === content}
                filterBy={content.value} /> : (
                <ProductTable
                  active={basicActive === content}
                  filterBy={content.value}
                />
              )}
            </MDBTabsPane>
          ))}
        </MDBTabsContent>
      </MDBCardBody>
    </MDBCard>
  );
}
