import isEmpty from "is-empty";

export const isUser = (obj, action = null) => {
  let errors = {};
  if (isEmpty(obj.firstName)) errors.firstName = "Please enter first name";
  if (isEmpty(obj.lastName)) errors.lastName = "Please enter last name";
  if (action !== "update") {
    if (isEmpty(obj.email) || !ValidateEmail(obj.email))
      errors.email = "Please enter valid email";

    if (isEmpty(obj.password) || obj.password.length < 8)
      errors.password = "Please enter password with atleast 8 characters";
    if (isEmpty(obj.confirmPassword) || obj.confirmPassword.length < 8)
      errors.confirmPassword =
        "Please enter confirm password with atleast 8 characters";
    if (
      !isEmpty(obj.confirmPassword) &&
      !isEmpty(obj.password) &&
      obj.confirmPassword !== obj.password
    )
      errors.confirmPassword = "Passwords must match";
  }

  if (isEmpty(obj.mobilePhone))
    errors.mobilePhone = "Please enter mobile number";

  if (
    !isEmpty(obj.mobilePhone) &&
    isNaN(parseFloat(obj.mobilePhone)) &&
    obj.mobilePhone.length < 10
  )
    errors.mobilePhone = "Enter valid phone number e.g 87612345678";
  if (isEmpty(errors)) {
    return { success: true };
  } else return { success: false, errors };
};

export const validLogin = (obj) => {
  let errors = {};
  if (isEmpty(obj.email) || !ValidateEmail(obj.email))
    errors.email = "Please enter valid email";
  if (isEmpty(obj.password) || obj.password.length < 8)
    errors.password = "Please enter password with atleast 8 characters";
  if (isEmpty(errors)) {
    return { success: true };
  } else return { success: false, errors };
};

export const validateRecovery = (data, recoveryMethod) => {
  let errors = {};
  console.log(data);
  switch (recoveryMethod) {
    case "EMAIL":
      if (!ValidateEmail(data)) errors.email = "Please enter valid email";
      break;
    case "PHONE":
      const { mobilePhone } = data;
      if (isEmpty(mobilePhone))
        errors.mobilePhone = "Please enter mobile number";
      if (!isEmpty(mobilePhone) && isNaN(parseFloat(mobilePhone)))
        errors.mobilePhone = "Enter valid phone number e.g 87612345678";
      if (
        !isEmpty(mobilePhone) &&
        !isNaN(parseFloat(mobilePhone)) &&
        mobilePhone.length < 10
      )
        errors.mobilePhone =
          "Phone number should be atleast 10 digits e.g 87612345678";
      break;
    default:
      break;
  }

  if (isEmpty(errors)) {
    return { success: true };
  } else return { success: false, errors };
};

export default function ValidateEmail(mail) {
  if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export const validateUpdateUser = (user) => {
  let errors = {};
  if (!isEmpty(user)) {
    if (user.imageUrl) {
      if (
        !["image/gif", "image/jpeg", "image/png"].includes(user.imageUrl.type)
      ) {
        errors.imageUrl = "Unsupported file type.";
      }
    }

    if (isEmpty(user.firstName)) errors.firstName = "Please enter first name";
    if (isEmpty(user.lastName)) errors.lastName = "Please enter last name";
    if (isEmpty(user.mobilePhone))
      errors.mobilePhone = "Please enter mobile number";
    if (!isEmpty(user.mobilePhone) && isNaN(parseFloat(user.mobilePhone)))
      errors.mobilePhone = "Enter valid phone number e.g 87612345678";

    if (!isEmpty(user.mobilePhone) && user.mobilePhone.length < 10)
      errors.mobilePhone = "Enter valid phone number e.g 87612345678";
    if (isEmpty(user.companyName))
      errors.companyName = "Please enter valid company name";

    if (isEmpty(user.address)) {
      errors.addressLineOne = "Please enter valid address.";
      errors.city = "Please enter valid city.";
      errors.parish = "Please enter valid parish.";
    } else {
      if (!user.address.lineOne) {
        errors.addressLineOne = "Please enter valid address.";
      }
      if (!user.address.city) {
        errors.city = "Please enter valid city.";
      }
      if (!user.address.parish) {
        errors.parish = "Please enter valid parish.";
      }
    }
  }
  if (isEmpty(errors)) {
    return { success: true };
  } else return { success: false, errors };
};

export const ValidatePasswords = (obj) => {
  let errors = {};
  if (isEmpty(obj.password) || obj.password.length < 8)
    errors.password = "Please enter password with atleast 8 characters";
  if (isEmpty(obj.confirmPassword) || obj.confirmPassword.length < 8)
    errors.confirmPassword =
      "Please enter confirm password with atleast 8 characters";
  if (
    !isEmpty(obj.confirmPassword) &&
    !isEmpty(obj.password) &&
    obj.confirmPassword !== obj.password
  )
    errors.confirmPassword = "Passwords must match";

  if (isEmpty(errors)) {
    return { success: true };
  } else return { success: false, errors };
};
