import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getProduct } from "../product_table/actions";
import AddToBasket from "../../basket/addToBasket";
import { handleImageUrl } from "../../../utils/product";
//import CurveTop from "../../shared/curveTop";
import ProductCarousel from "../productCarousel";
import { setState } from "../product_table/updateSlice";
import RotateLoader from "react-spinners/RotateLoader";
import "./index.css";
const override = {
  display: "block",
  margin: "0 auto",
};
const ProductDetails = () => {
  const { product, updateLoading } = useSelector(
    (state) => state.product.update
  );
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getProduct(productId));
  }, [dispatch, productId]);

  return (
    <div className="page-wrapper">
      {updateLoading ? (
        <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
          <RotateLoader color="royalblue" cssOverride={override} size={50} />
        </div>
      ) : (
        <>
          <MDBContainer fluid>
            <MDBBreadcrumb className="mb-0">
              <MDBBreadcrumbItem>
                <Link
                  onClick={() => dispatch(setState({ product: {} }))}
                  to="/products"
                >
                  Products
                </Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>{product?.name}</MDBBreadcrumbItem>
            </MDBBreadcrumb>
          </MDBContainer>
          {/* <CurveTop /> */}
          <MDBContainer
            className="d-flex align-items-center justify-content-center"
            fluid
          >
            <MDBRow className="w-100">
              <MDBCol md={6}>
                <div>
                  {
                    <img
                      className="img-fluid"
                      alt="..."
                      src={handleImageUrl(product?.imageUrl, {
                        width: 400,
                        height: 400,
                      })}
                    ></img>
                  }
                </div>
              </MDBCol>
              <MDBCol className="d-flex" md={6}>
                <div className="text-sm-center text-md-start w-100">
                  <h4 className="section-header">{product?.name}</h4>
                  <p>{product?.description}</p>
                  <h5
                    style={{ fontWeight: 600, fontSize: "20px" }}
                    className="text-success"
                  >
                    ${product?.price}
                  </h5>
                  <div className="mt-1">
                    <AddToBasket product={product} />
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      )}
      {product?.category ? (
        <MDBContainer className="mt-3 bg p-1" fluid>
          <h3 className="section-header">Other Products You May Like</h3>
          <ProductCarousel category={product.category._id} />
        </MDBContainer>
      ) : null}
      {/* <CurveTop /> */}
      <MDBContainer className="mt-2" fluid>
        <MDBBtn
          onClick={() => {
            navigate("/products");
            dispatch(setState({ product: {} }));
          }}
          children="See more products"
        />
      </MDBContainer>
    </div>
  );
};
export default ProductDetails;
