import isEmpty from "is-empty";

export const validateProduct = (product) => {
  let errors = {};

  if (isEmpty(product.name)) {
    errors.name = "Product name required";
  }
  if (isEmpty(product.price)) {
    errors.price = "Product price required";
  }
  if (isEmpty(product.quantity)) {
    errors.quantity = "Product quantity required";
  }
  if (isEmpty(product.unit)) {
    errors.unit = "Product unit required";
  }
  if (isEmpty(product.category)) {
    errors.category = "Product category required";
  }
  if (isEmpty(errors)) {
    return { success: true };
  } else return { success: false, errors };
};

export const handleImageUrl = (url, sizeParams) => {
  let newUrl = "";
  if (url) {
    const size = `upload/w_${sizeParams?.width},h_${sizeParams?.height},c_scale`;
    const splitUrl = url?.split("upload");
    newUrl = `${splitUrl[0]}${size}${splitUrl[1]}`;
  }
  return newUrl;
};

export const calculatePercentage = (product) => {
  if(product?.quantity === 0)return 0
  if(product?.lowCount < product?.quantity){
    const percentageUsed = (product?.lowCount / product?.quantity) * 100;
    const percentage = 100 - percentageUsed;
    return isNaN(percentage) ? 0 : Math.floor(percentage);
  }
  return 1
};
