import { MDBContainer } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
    return <MDBContainer>
        <h6>Effective Date: June 29, 2023</h6>
        <p>At <Link to={'#'}>chomainefoods.com</Link>, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and store your information when you use our website and services. By accessing or using our website, you consent to the practices described in this Privacy Policy.</p>
        <span>Information We Collect:</span>
        <ol>
            <li>Personal Information:<br />
                We may collect personal information such as your name, email address, phone number, billing and shipping addresses, and payment information when you create an account, place an order, or interact with our website.</li>
            <li>Non-Personal Information: <br />
                We may also collect non-personal information such as your IP address, browser type, device information, and website usage data. This information is collected to improve our services and enhance your user experience.</li>
        </ol>
        <span>Use of Information:</span>
        <ol>
            <li>Personal Information: <br />
                We may use your personal information to process your orders, communicate with you regarding your purchases, respond to your inquiries or requests, provide customer support, and improve our services. We may also use your information to send you promotional offers, updates, or newsletters if you have opted to receive such communications.</li>
            <li>Non-Personal Information: <br />
                Non-personal information is primarily used to analyze website usage patterns, track trends, and gather demographic information. This helps us improve our website, understand customer preferences, and personalize your experience.</li>
        </ol>
        <span>Disclosure of Information:</span>
        <ol>
            <li>Legal Requirements: <br />
                We may disclose your information if required to do so by law or in response to a valid request by a governmental or law enforcement authority.</li>
            <li>Business Transfers: <br />
                In the event of a merger, acquisition, or sale of our business, customer information may be transferred as part of the transaction. We will notify you via email or prominent notice on our website if such a transfer occurs.</li>
        </ol>
        <span>Data Security:</span>
        <p>We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security of your information.</p>
        <span>Third-Party Links:</span>
        <p>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of those third parties before providing any personal information.</p>
        <span>Your Choices:</span>
        <p>You can manage your personal information and communication preferences by accessing your account settings or by contacting us directly. You may choose to unsubscribe from our promotional communications at any time.</p>
        <span>Children's Privacy:</span>
        <p>Our website is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child, we will take steps to delete that information.</p>
        <span>Changes to the Privacy Policy:</span>
        <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website. We encourage you to review this Privacy Policy periodically for any updates.</p>
        <span>Contact Us:</span>
        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at <Link to="#">info@chomainefoods.com</Link></p>
        <p>By using our website, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your personal information as outlined herein.</p>
    </MDBContainer>
}